import { ISettings } from '@/entities/types'
import { useQueryRequest } from '@/hooks/requestHooks'
import useMatch from '@/hooks/useMatch'
import { StaticPageSlug, StaticPageSlugForProject } from '@/types/pageType'
import { NextPage } from 'next'
import { StaticPageComponent } from '@/components/contentTypes/types'
import AppPage from '@/components/organisms/AppPage'
import ForgetAccountPage from '@/organisms/ForgetAccountPage'

const SLUG_COMPONENT_MAP: Record<StaticPageSlug, StaticPageComponent> = {
  [StaticPageSlug.app]: AppPage,
}

const SLUG_COMPONENT_MAP_FOR_PROJECT: Record<StaticPageSlugForProject, StaticPageComponent> = {
  [StaticPageSlugForProject['vergeet-mij']]: ForgetAccountPage,
}

/**
 * We can use this component to render pages that do not serve content from the back end.
 */
const StaticPage: NextPage = () => {
  const {
    slugs,
    match: { params },
  } = useMatch()
  const { data, isError, isLoading } = useQueryRequest<ISettings>('getSettings', undefined, {
    projectSlug: params.projectSlug,
    env: 'closed',
  })

  if (isLoading || isError) {
    return null
  }

  let StaticComponent
  if (params.projectSlug === 'portal') {
    StaticComponent = slugs ? SLUG_COMPONENT_MAP[slugs[0] as StaticPageSlug] : () => null
  } else {
    StaticComponent = slugs
      ? SLUG_COMPONENT_MAP_FOR_PROJECT[slugs[1] as StaticPageSlugForProject]
      : () => null
  }

  return <StaticComponent settings={data} />
}

export default StaticPage
