import { IWishListOptionOrder, WishListOptionOrder } from '@/entities/wishList'

const getOptionTotalPrice = (
  originalPrice: number,
  orders?: WishListOptionOrder[] | IWishListOptionOrder[]
) => {
  if (orders) {
    return orders.reduce((acc, curr) => acc + curr.amount * curr.salesPrice, 0)
  }

  return originalPrice
}

export default getOptionTotalPrice
